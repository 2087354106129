export const LOGIT_INTERCEPTS = {
    1: -0.572315346192389,
    2: -2.3710249646839037,
    3: -3.4039033808138766,
    4: -1.4763530710828032,
    5: -1.8900457047411943,
    6: -2.487063328598679,
    7: -2.358341585879204,
    8: -2.882491408518071,
    9: -3.9294016931312203,
    10: -3.4973270348215357,
    11: -3.092998582144249,
    12: -2.2926963748588993,
    13: -1.617730433134762,
    14: -2.582887699260282,
    15: 0.0,
    16: -1.2856000771931753,
    17: -1.1059692260446417,
    18: -2.2310146051129935,
    19: -0.5680866520326437,
    20: -1.6117334009731135,
};
export const LOGIT_PFA = {
    1: 0.15106181823442552,
    2: 0.24904479616177352,
    3: 0.227675252493504,
    4: 0.1834322121865516,
    5: 0.2662750297749126,
    6: 0.2856626995255294,
    7: 0.24293266060130217,
    8: 0.2796142409553484,
    9: 0.36571809433721314,
    10: 0.20829964336342977,
    11: 0.15748679467845336,
    12: 0.21312752203763077,
    13: 0.21219355758706868,
    14: 0.24413395933376256,
    15: 0.28089568260308667,
    16: 0.19092591320847374,
    17: 0.15677840543388916,
    18: 0.18107551776665037,
    19: 0.26452236363443055,
    20: 0.2806914888538869,
};
export const LOGIT_NFA = {
    1: 0.45044462848844097,
    2: 0.31456213092259344,
    3: 0.29755685554849487,
    4: 0.5286034310472983,
    5: 0.3572380533448058,
    6: 0.39195520014477125,
    7: 0.26635792978382045,
    8: 0.29960699892217835,
    9: 0.22621174774417455,
    10: 0.3793203219402373,
    11: 0.41681685201247476,
    12: 0.46092046498985517,
    13: 0.4888437859783075,
    14: 0.33321019483945913,
    15: 0.5068814419787235,
    16: 0.4203605153586767,
    17: 0.46318810240105485,
    18: 0.46516574618965795,
    19: 0.42053814708906206,
    20: 0.3668611187691623,
};
export const LOGIT_IS_POS2 = {
    1: 0.011731811298306201,
    2: 0.07798672579741044,
    3: 0.2348724411443365,
    4: 0.2923113112169508,
    5: 0.24353969521167368,
    6: 0.2524468078917118,
    7: 0.3513400356294753,
    8: -0.05056379912652935,
    9: 0.2181588858320363,
    10: 0.42153870608115357,
    11: 0.5441316355070511,
    12: 0.2873220085144607,
    13: 0.34950530956197323,
    14: 0.25558339816103526,
    15: 0.10217877729792446,
    16: 0.049734976355973626,
    17: 0.06640742678977393,
    18: 0.35249308567817966,
    19: 0.18376352468733861,
    20: 0.09385007535722284,
};
export const LOGIT_IS_POS3 = {
    1: 0.3035400458766529,
    2: 0.44169989871859067,
    3: 0.5765641120753523,
    4: 0.5912719335443021,
    5: 0.5653065554388917,
    6: 0.3914116378103785,
    7: 0.6099242370148689,
    8: 0.15904769971902863,
    9: 0.5437044451808214,
    10: 0.819503484254991,
    11: 0.591312849825903,
    12: 0.5539556734543557,
    13: 0.5216843938783119,
    14: 0.47592544319039626,
    15: 0.4102125955279302,
    16: 0.3110607966460486,
    17: 0.24791018873439022,
    18: 0.6518181416279174,
    19: 0.5210126825538005,
    20: 0.5507208776124378,
};
export const LOGIT_IS_POS4 = {
    1: 0.4749290457544689,
    2: 0.6677448087363302,
    3: 0.7929410722977402,
    4: 0.8800771966212567,
    5: 0.8116790918863027,
    6: 0.6865159297914329,
    7: 0.8420545383693719,
    8: 0.5815844763214828,
    9: 0.9921026084949217,
    10: 1.0326692095446663,
    11: 1.058718392801785,
    12: 0.9706742020410317,
    13: 0.9256269220875435,
    14: 0.7193447182651764,
    15: 0.5256225970394934,
    16: 0.6953625540796796,
    17: 0.5346258212441198,
    18: 0.9470031112129274,
    19: 0.7464763913774678,
    20: 0.8359952798630121,
};
